<template>
  <vx-card style="padding-left: 10px">
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="$router.push({ name: 'create-admin-user' })"
          icon-pack="feather"
          icon="icon-edit"
        >Create Admin</vs-button>
      </div>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select placeholder="10" autocomplete v-model="dataTableParams.limit" class="mr-6">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">First Name</vs-th>
          <vs-th sort-key="lastName">Last Name</vs-th>
          <vs-th sort-key="email">Email Address</vs-th>
          <vs-th sort-key="userType">Type</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <!-- <vs-th sort-key="hasApprovedByAdmin">Super Admin Approval</vs-th> -->
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].firstName">{{ data[indextr].firstName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].lastName">{{ data[indextr].lastName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td
              :data="data[indextr].userType"
            >{{ data[indextr].userType === "superAdmin" ? "Super Admin" : "Admin" }}</vs-td>
            <vs-td :data="data[indextr].status">
              <p v-if="data[indextr].status === 'Pending_Approval'" class="text-sm">Pending Approval</p>
              <p v-else class="text-sm">{{data[indextr].status}}</p>
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Admin Details">
                <vs-button
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        userType: "admin"
      },
      serverResponded: false,
      users: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      radios2: "primary",
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("admin", ["fetchAdmins"]),
    moment(date) {
      return moment(date);
    },
    getAdminsList() {
      let self = this;
      this.fetchAdmins(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.noDataText = "No Data Available";
      });
      this.serverResponded = true;
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getAdminsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getAdminsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAdminsList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "edit-admin-user", params: { id: id } });
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getAdminsList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getAdminsList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getAdminsList();
  }
};
</script>
